import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { graphql, PageProps } from "gatsby";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import Card from "src/atoms/Card";
import Layout from "src/components/Layout";
import Markdown from "src/components/Markdown";
import StructuredText from "src/components/StructuredText";

const encode = (data: Record<string, string>) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

const FormContact = () => {
  const [isDone, setIsDone] = useState(false);
  const contact = useForm({
    defaultValues: {
      name: "",
      email: "",
      message: "",
    },
  });

  return (
    <form
      onSubmit={contact.handleSubmit((data) =>
        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({ "form-name": "sfpr-contact", ...data }),
        })
          .then(() => {
            contact.reset();
            setIsDone(true);
          })
          .catch((error) => console.error(error))
      )}
    >
      <input name="form-name" type="hidden" value="sfpr-contact" />

      <VStack align="stretch" spacing={6}>
        <HStack spacing={6}>
          <FormControl isRequired>
            <FormLabel htmlFor="name">Votre nom complet</FormLabel>

            <Input {...contact.register("name")} />
          </FormControl>

          <FormControl isRequired>
            <FormLabel htmlFor="email">Votre adresse e-mail</FormLabel>

            <Input type="email" {...contact.register("email")} />
          </FormControl>
        </HStack>

        <FormControl isRequired>
          <FormLabel htmlFor="message">
            Votre message pour les administrateurs de la SFPR
          </FormLabel>

          <Textarea {...contact.register("message")} />
        </FormControl>

        {isDone ? (
          <Alert status="success">
            <AlertIcon />
            Votre message a bien été envoyé !
          </Alert>
        ) : (
          <Button
            alignSelf="center"
            isDisabled={!contact.formState.isValid}
            isLoading={contact.formState.isSubmitting}
            px={8}
            type="submit"
          >
            Envoyer mon message
          </Button>
        )}
      </VStack>
    </form>
  );
};

const FormAdhesion = () => {
  const [isDone, setIsDone] = useState(false);

  const adhesion = useForm({
    defaultValues: {
      name: "",
      placeAndYearOfGraduation: "",
      currentAffiliation: "",
      email: "",
      researchThematics: "",
    },
  });

  return (
    <form
      onSubmit={adhesion.handleSubmit((data) =>
        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({ "form-name": "sfpr-adhesion", ...data }),
        })
          .then(() => {
            adhesion.reset();
            setIsDone(true);
          })
          .catch((error) => console.error(error))
      )}
    >
      <input name="form-name" type="hidden" value="sfpr-adhesion" />

      <VStack align="stretch" spacing={6}>
        <HStack spacing={6}>
          <FormControl isRequired>
            <FormLabel htmlFor="name">Votre nom complet</FormLabel>

            <Input {...adhesion.register("name")} />
          </FormControl>

          <FormControl isRequired>
            <FormLabel htmlFor="email">Votre adresse e-mail</FormLabel>

            <Input type="email" {...adhesion.register("email")} />
          </FormControl>
        </HStack>

        <HStack spacing={6}>
          <FormControl isRequired>
            <FormLabel htmlFor="placeAndYearOfGraduation">
              Lieu du Doctorat et année
            </FormLabel>

            <Input {...adhesion.register("placeAndYearOfGraduation")} />
          </FormControl>

          <FormControl isRequired>
            <FormLabel htmlFor="currentAffiliation">
              Affiliation actuelle
            </FormLabel>

            <Input {...adhesion.register("currentAffiliation")} />
          </FormControl>
        </HStack>

        <FormControl isRequired>
          <FormLabel htmlFor="researchThematics">
            Vos thématiques de recherche
          </FormLabel>

          <Textarea {...adhesion.register("researchThematics")} />
        </FormControl>

        {isDone ? (
          <Alert status="success">
            <AlertIcon />

            {"Votre demande d'adhésion a bien été prise en compte"}
          </Alert>
        ) : (
          <Button
            alignSelf="center"
            colorScheme="linkedin"
            isDisabled={!adhesion.formState.isValid}
            isLoading={adhesion.formState.isSubmitting}
            px={8}
            type="submit"
          >
            {"Envoyer ma demande d'adhésion"}
          </Button>
        )}
      </VStack>
    </form>
  );
};

/** */
const Contact = ({
  data: { datoCmsContactPage },
}: PageProps<Gatsby.ContactQuery>) => {
  return (
    <Layout>
      <Helmet
        meta={[
          {
            name: "description",
            content:
              "Si vous souhaitez nous contacter, remplissez le formulaire suivant.",
          },
        ]}
        title="Contact et adhésion"
      />

      <Container maxW="container.lg">
        <Card size="lg">
          <Markdown mb={10}>
            <StructuredText data={datoCmsContactPage?.contactBody} />
          </Markdown>

          <FormContact />

          <Divider mb={10} mt="60px" />

          <Box>
            <Markdown mb={10}>
              <StructuredText data={datoCmsContactPage?.adhesionBody} />
            </Markdown>
          </Box>

          <FormAdhesion />
        </Card>
      </Container>
    </Layout>
  );
};

export default Contact;

export const query = graphql`
  query Contact {
    datoCmsContactPage {
      adhesionBody {
        value
      }
      contactBody {
        value
      }
    }
  }
`;
